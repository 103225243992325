@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

$primary-color: #194049;

// Columbia-Sans (used for headers)
@font-face {
  font-family: 'Columbia-Sans';
  src: url('./static/fonts/columbia-sans/columbiasanswdisplay-medium.woff2')
      format('woff2'),
    url('./static/fonts/columbia-sans/columbiasanswdisplay-medium.eot?#iefix')
      format('embedded-opentype');
}

// Area font family is used for the following:
// a, p, span, ul, li, label, input, textarea, button
@font-face {
  font-family: 'Area-Extended';
  src: url('./static/fonts/area/Area\ Extended\ Black/AreaExtended-Black.woff2')
      format('woff2'),
    url('./static/fonts/area/Area\ Extended\ Black/AreaExtended-Black.woff')
      format('woff'),
    url('./static/fonts/area/Area\ Extended\ Black/AreaExtended-Black.otf')
      format('opentype');
}

@font-face {
  font-family: 'Area-Normal-Black';
  src: url('./static/fonts/area/Area\ Normal\ Black/AreaNormal-Black.woff2')
      format('woff2'),
    url('./static/fonts/area/Area\ Normal\ Black/AreaNormal-Black.woff')
      format('woff'),
    url('./static/fonts/area/Area\ Normal\ Black/AreaNormal-Black.otf')
      format('opentype');
}

@font-face {
  font-family: 'Area-Normal-Bold';
  src: url('./static/fonts/area/Area\ Normal\ Bold/AreaNormal-Bold.woff2')
      format('woff2'),
    url('./static/fonts/area/Area\ Normal\ Bold/AreaNormal-Bold.woff')
      format('woff'),
    url('./static/fonts/area/Area\ Normal\ Bold/AreaNormal-Bold.otf')
      format('opentype');
}

@font-face {
  font-family: 'Area-Normal-Semibold';
  src: url('./static/fonts/area/Area\ Normal\ Semibold/AreaNormal-Semibold.woff2')
      format('woff2'),
    url('./static/fonts/area/Area\ Normal\ Semibold/AreaNormal-Semibold.woff')
      format('woff'),
    url('./static/fonts/area/Area\ Normal\ Semibold/AreaNormal-Semibold.otf')
      format('opentype');
}

* {
  box-sizing: border-box;
  margin: 0;
  font-family: 'Area-Normal-Semibold', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

body, html {
  width: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a,
p,
span,
ul,
li,
label,
input,
textarea,
button {
  font-size: 12px;
  font-family: 'Area-Normal-Semibold', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 16px;
  font-family: 'Columbia-Sans', sans-serif;
}

.css-1eck85g-MuiDrawer-docked .MuiDrawer-paper {
  overflow-x: hidden !important;
}

.text-huge {
  font-size: 16px;
  font-family: 'Area-Extended', sans-serif;
  font-style: normal;
  font-weight: 900;
  line-height: 102%;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #194049;
}

.text-big {
  font-size: 18px;
  font-family: 'Area-Normal-Semibold', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 200%;
  letter-spacing: 0.03em;
  color: #194049;
}
.text-small {
  font-size: 15px;
  font-family: 'Area-Normal-Semibold', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 200%;
  letter-spacing: 0.02em;
  color: #194049;
}
.text-tiny {
  font-family: 'Area-Normal-Semibold', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 200%;
  letter-spacing: 0.02em;
  color: #194049;
}


blockquote {
  border-left-width: 8px;
  --tw-border-opacity: 1;
  border-color: #FFFC9B;
  height: 100%;
  left:0px;
  padding-left: 20px;
  // position: absolute;
  }

  .text-small strong{
  font-size: 15px;
  font-family: 'Area-Normal', sans-serif;
  font-style: normal;
  // font-weight: 900;
  font-size: 15px;
  line-height: 200%;
  letter-spacing: 0.02em;
  color: #194049;
  }





  // ol li {
  //   list-style-type: decimal;
  //   padding: 10px !important;
  //   font-size: 15px;
  //   font-family: 'Area-Normal', sans-serif;
  //   font-style: normal;
  // }


  // li ul li {
  //   list-style-type: disc;
  //   padding: 5px !important;
  //   font-size: 15px;
  //   font-family: 'Area-Normal', sans-serif;
  //   font-style: normal;
  // }

::-moz-selection {
  color: $primary-color;
  background: #D2E0CB;
}

::selection {
  color: $primary-color;
  background: #D2E0CB;
}

a:link,
a:visited,
a:hover,
a:active {
  color: $primary-color;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a:active {
  text-decoration: underline;
}

::-webkit-scrollbar {
  width: 4px;
  border-radius: 15px;
  display: none !important;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 15px;
}

// #c8c8c8;
::-webkit-scrollbar-thumb {
  background-color: rgb(40, 64, 74);
  width: 8px;
  border-radius: 15px;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

.fade-enter.fade-exit-active {
  opacity: 0;
  transition: opacity 250ms ease-in;
  display: none;
}

.fade-exit {
  opacity: 0;
  z-index: 0;
  display: none;
}

// Loading Icon
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loading-icon-container {
  width: 80px;

  display: flex;
  justify-content: space-around;
  align-items: center;
}

.loading-icon {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  border: 5px solid rgba(25, 64, 73, 0.2);
  border-top-color: #D2E0CB;
  display: inline-block;
  // margin: 0 8px;

  -webkit-animation-name: spin;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;

  animation-name: spin;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  position: relative;
}

.overshot {
  -webkit-animation-timing-function: cubic-bezier(0.65, 1.5, 0.6, 0.95);
  animation-timing-function: cubic-bezier(0.65, 1.5, 0.6, 0.95);
}

.downhill {
  -webkit-animation-timing-function: cubic-bezier(0.3, 0.1, 0.3, 0.85);
  animation-timing-function: cubic-bezier(0.3, 0.1, 0.3, 0.85);
}

// react-transition classes below-
// appear?: string | undefined;
// appearActive?: string | undefined;
// appearDone?: string | undefined;
// enter?: string | undefined;
// enterActive?: string | undefined;
// enterDone?: string | undefined;
// exit?: string | undefined;
// exitActive?: string | undefined;
// exitDone?: string | undefined;

// @font-face {
//   font-family: 'Avenir-Book';
//   src: local('Avenir-Book'),
//     url('./static/fonts/avenir/Avenir-Book.otf') format('opentype');
// }
// @font-face {
//   font-family: Gilroy-Regular;
//   src: local('Gilroy-Regular'),
//     url('./static/fonts/gilroy/Gilroy-Regular.ttf') format('truetype');
// }

.css-46bh2p-MuiCardContent-root{
  padding-top: 12px !important;
}

// .css-wpxl99-MuiFormGroup-root {
//   margin-left: auto;
//   margin-right: auto;
// }


.text-huge {
  font-size: 16px;
  font-family: 'Area-Extended', sans-serif;
  font-style: normal;
  font-weight: 900;
  line-height: 102%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #194049;
}
.text-big {
  font-size: 22px;
  font-family: 'Area-Normal-Semibold', sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 200%;
  letter-spacing: 0.02em;
  margin-left: -50px;
  color: #194049;
}
.text-small {
  font-family: 'Area-Normal-Semibold', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 200%;
  letter-spacing: 0.02em;
  color: #194049;
}
.text-tiny {
  font-family: 'Area-Normal-Semibold', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 200%;
  letter-spacing: 0.02em;
  color: #194049;
}



blockquote .text-big {
  font-size: 30px !important;
  font-family: 'Columbia-Sans', sans-serif;
  width: 90%;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: -0.04em;
}

blockquote .text-small {
  font-size: 30px !important;
  font-family: 'Columbia-Sans', sans-serif;
  width: 90%;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: -0.04em;
}

  blockquote .text-small {
    font-size: 30px !important;
    font-family: 'Columbia-Sans', sans-serif;
    width: 90%;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: -0.04em;
    }

    // blockquote{
    // padding-top: 20px !important;
    // padding-bottom: 20px !important;
    // }

  .text-small strong{
  font-size: 16px;
  font-family: 'Area-Normal', sans-serif;
  font-style: normal;
  // font-weight: 900;
  font-size: 15px;
  line-height: 200%;
  letter-spacing: 0.02em;
  color: #194049;
  }
  .text-small s{
    font-size: 30px !important;
    font-family: 'Columbia-Sans', sans-serif;
    width: 90%;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: -0.04em;
    text-decoration: none !important;
  }


  // ol li {
  //   list-style-type: decimal;
  //   padding: 10px !important;
  //   font-size: 16px;
  //   font-family: 'Area-Extended' !important;
  //   font-style: normal;
  //   color:#194049;
  //   margin-bottom: 5px;
  //   position: relative;
  // }

 

// ol li {
//   list-style-type: decimal;
//   padding: 10px !important;
//   font-size: 16px;
//   font-family: 'Area-Normal', sans-serif;
//   font-style: normal;
//   color: #194049;
//   margin-bottom: 5px;
//   position: relative;
//   font-family: 'Area Extended';
//   font-weight: 900;
// }

  // li ul li {
  //   list-style-type: none;
  //   padding: 5px !important;
  //   font-size: 16px;
  //   font-family: 'Area-Extended' !important;
  //   font-style: normal;
  //   color:#194049;
  // }


  .signPadContainer {
    // background-color: rgb(238, 235, 235);
    border: 1px solid #A9A9A9;
    padding: 0 10px 10px;
  }


  .signCanvas{
    width: 100%;
    height: 300px;
  }
  
  .signmodal{
    width: 100%;
    max-width: 1000px;
    border: 5px;
    padding: 10px;
    background-color: white;
    box-sizing: border-box;
  }
  
  .signmodal__bottom {
    // text-align: right;
    margin-top: 10px;
  }
  
  .signmodal__bottom button {
    padding: 8px 10px;
    border-radius: 4px;
  }

  .signButtons {
    display: flex;
    justify-content: space-between;
  }

  .signPad__penColors{
    cursor: pointer;
    text-align: center;
    margin-bottom: 15px;
  }                                                                                                                  
  
  .signPad__penColors p{
    display: inline-block; 
    margin-right: 5px;
  }
  
  .signPad__penColors span{
    padding: 0px 4px 3px 8px;
    border-radius: 100%;
    margin-right: 5px;
  }
  .signature {
    width: 200px;
    height: 70px;
    // margin-bottom: 20px;
    // box-shadow: 0 0 4px 2px gainsboro;
    padding: 10px;
    object-fit: contain;
  }


  .removeUnderline{
    text-decoration: none !important;
  }

  .newBorder{
    border: 2px solid #A9A9A9;
    border-style: dotted;
    cursor: pointer;
  }

  .iconheight{
    width: 12px;
    height: 12px;
    cursor: pointer;
  }

  .iconHeight{
    width: 25px;
    height: 25px;
    cursor: pointer;
  }

  .theWidth{
    width: 400px;
  }

  .topRounded{
    border-top: 6px;
  }

  .linearColor {
    background: linear-gradient(to right, #E4F0DE, #C7D8E4);
  }



  .spaceEvenly {
    display: flex;
    justify-content: space-evenly;
  }


  .centerItems {
    display: flex;
    justify-content: center !important;
  }
  .justCenter {
    justify-content: center !important;
  }

  .customJustifyBetween {
    justify-content: space-between !important;
  }
  .flexJustBetween {
    display: flex;
    justify-content: space-between !important;
  }


  .fullCenter {
    display: flex;
    align-items: center !important;
    justify-content: center !important;
  }

  
  .css-1t52dah{
    justify-content: center !important;

  }

 .avatarWH {
  width: 200px;  
  height: 200px;  
  color: #fff;
 }


// .plan-widget {
//   background-image: url('https://res.cloudinary.com/mother-honestly/image/upload/v1665998577/Mask_Group_1_q94vna.png');
//   height: 240px;
//   width: 100%;
//   background-color: limegreen;
//   background-repeat: no-repeat;
//   background-position: center;
//   background-size: cover;
// }
.subscriptionPlan{
  /* Choose a plan gradient */
box-sizing: border-box;
background: radial-gradient(114.17% 114.17% at 50% -14.17%, #BFD0B7 7.5%, #BFD0B7 10.42%, #D2E0CB 19.25%, #ECF1E9 33.24%, #EDF3EB 46%, #EEF3EC 54%, #F2F6F0 90.33%);
// opacity: 0.33;
border: 1px solid #000000;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
color:#194049;
}

.subscriptionCard{
  /* Choose a plan gradient */
box-sizing: border-box;
background: radial-gradient(114.17% 114.17% at 50% -14.17%, #BFD0B7 7.5%, #BFD0B7 10.42%, #D2E0CB 19.25%, #ECF1E9 33.24%, #EDF3EB 46%, #EEF3EC 54%, #F2F6F0 90.33%);
// opacity: 0.33;
// border: 1px solid #000000;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
color:#BFD0B7;
}

.footerBg{
  background: linear-gradient(180deg, #F2F2F2 0%, #FFFFFF 100%);
}

.css-vpjy82 {
  overflow-y: hidden !important;
}

.css-1gtanqs {
  margin-left: -30px;
}

// Google Map
.map-container {
  height: 100%;
  width: 100%;
}

.mini-map-container {
  height: 256px;
  width: 100%;
}


// .modal-backdrop {
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background-color: rgba(0, 0, 0, 0.5);
//   z-index: 1048; /* Lower than the select dropdown */
// }

// .withdraw-dialog {
//   position: fixed;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   background-color: white;
//   padding: 20px;
//   z-index: 1049; /* Lower than the select dropdown */
// }

// .sorted-bank-container {
//   position: relative;
//   z-index: 1050; /* Higher than the modal's z-index */
// }
